import Grid from "@mui/material/Grid";

import MDBox from "../../components/MDBox";

import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ReportsBarChart from "../../examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "../../examples/Charts/LineCharts/ReportsLineChart";
import FilterNavbar from "../../examples/Filters/FilterNavbar";
import CustomPieChart from "../../examples/Charts/PieCharts/CustomPieChart";
import { api } from "../../App";
import { useState, useEffect } from "react";
import React, { Component } from "react";

import { useLocation } from "react-router-dom";

import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";

function Basic() {
  const [chart, setChart] = useState({
    users_chart:{total_users_count:0, inactive_users_count: 0},
    active_users_chart: {total_active_users:0, total_loyal_users: 0}});
  const [total_users_chart, setTotalUsersChart] = useState({labels:[], datasets:[]});
  const [active_users_chart, setActiveUsersChart] = useState({labels:[], datasets:[]});
  const [loyal_users_chart, setLoyalUsersChart] = useState({labels:[], datasets:[]});
  const { pathname } = useLocation();


  const preparingData = () =>{
     // TG.WebApp.ready()
    // const initData = TG.WebApp.initData
    // TG.WebApp.disableVerticalSwipes()
    // const encodedInitData = encodeURIComponent(initData);
    api.get("/api/UltraMegaAntiBruteForceEndpointEggGameAdmin/getStats"
    ).then(response => {
      console.log(response.data)
      var total_usersto = {}
      var active_usersto = {}
      var loyal_usersto = {}

      var labels_d = []
      var datasets_d = []

      var active_labels_d = []
      var active_datasets_d = []

      var loyal_labels_d = []
      var loyal_datasets_d = []

      response.data.users_chart.data.forEach(dd => {
        labels_d.push(dd["date"].slice(-2))
        datasets_d.push(dd["total_count"])
      });

      response.data.active_users_chart.data.forEach(chd => {
        active_labels_d.push(chd["start_date"].slice(8, 11))
        loyal_labels_d.push(chd["start_date"].slice(8, 11))

        active_datasets_d.push(chd.active_sessions)
        loyal_datasets_d.push(chd.loyal_sessions)
      });

      total_usersto["labels"] = labels_d
      total_usersto["datasets"] = { label: "Users", data: datasets_d }

      active_usersto["labels"] = active_labels_d
      active_usersto["datasets"] = { label: "Users", data: active_datasets_d }

      loyal_usersto["labels"] = loyal_labels_d
      loyal_usersto["datasets"] = { label: "Users", data: loyal_datasets_d }

      setChart(response.data)
      setTotalUsersChart(total_usersto)
      setActiveUsersChart(active_usersto)
      setLoyalUsersChart(loyal_usersto)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() =>{
    preparingData();
   },[])

  return (
    <DashboardLayout>
      {/* <DashboardNavbar/> */}
      <FilterNavbar />
      <MDBox mt={4.5}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} sm={4} lg={3}>
            <MDBox sx={{height: "100%"}}>
              <CustomPieChart
                chart={chart}
                />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={8} sm={8} lg={9}>
            <MDBox mt={3}>
              <ReportsLineChart
                color="info"
                title={"Новые пользователи"}
                description={
                  <>
                    Общее число пользователей: <strong>{chart.users_chart.total_users_count}</strong>
                  </>
                }
                date="just updated"
                chart={total_users_chart}
              />
            </MDBox>
          </Grid>
        </Grid>

        {/* <Grid container spacing={3}> */}
          <Grid item pt={3} xs={12}>
            <MDBox mt={3} mb={3}>
              <ReportsLineChart
                color="success"
                title={`Активные игроки: ${chart.active_users_chart.total_active_users}`}
                description="Заходят в игру более 2х раз в сутки"
                date="just updated"
                chart={active_users_chart}
              />
            </MDBox>
          </Grid>
        {/* </Grid> */}

        {/* <MDBox mt={3}> */}
          {/* <Grid container spacing={3}> */}
            <Grid item pt={3} xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="warning"
                  title={`Лояльные игроки: ${chart.active_users_chart.total_loyal_users}`}
                  description="Заходят в игру не более 1го раза в сутки"
                  date="just updated"
                  chart={loyal_users_chart}
                />
              </MDBox>
            </Grid>
          {/* </Grid> */}
        {/* </MDBox> */}

      </MDBox>

    </DashboardLayout>
    )
}


// class Basic extends Component {
//   state = {
//     chart: {
//       users_chart:{total_users_count:0, inactive_users_count: 0},
//       active_users_chart: {total_active_users:0, total_loyal_users: 0}},
//     total_users_chart: {labels:[], datasets:[]},
//     active_users_chart: {labels:[], datasets:[]},
//     loyal_users_chart: {labels:[], datasets:[]}
//   }


//   componentDidMount = async () => {
//     // TG.WebApp.ready()
//     // const initData = TG.WebApp.initData
//     // TG.WebApp.disableVerticalSwipes()
//     // const encodedInitData = encodeURIComponent(initData);

//     await api.get("/api/UltraMegaAntiBruteForceEndpointEggGameAdmin/getStats"
//     ).then(response => {
//       this.setState({
//         chart:response.data
//       })
//       console.log(response.data)
//       var total_usersto = {}
//       var active_usersto = {}
//       var loyal_usersto = {}

//       var labels_d = []
//       var datasets_d = []

//       var active_labels_d = []
//       var active_datasets_d = []

//       var loyal_labels_d = []
//       var loyal_datasets_d = []

//       response.data.users_chart.data.forEach(dd => {
//         labels_d.push(dd["date"].slice(-2))
//         datasets_d.push(dd["total_count"])
//       });

//       response.data.active_users_chart.data.forEach(chd => {
//         active_labels_d.push(chd["start_date"].slice(8, 11))
//         loyal_labels_d.push(chd["start_date"].slice(8, 11))

//         active_datasets_d.push(chd.active_sessions)
//         loyal_datasets_d.push(chd.loyal_sessions)
//       });

//       total_usersto["labels"] = labels_d
//       total_usersto["datasets"] = { label: "Users", data: datasets_d }

//       active_usersto["labels"] = active_labels_d
//       active_usersto["datasets"] = { label: "Users", data: active_datasets_d }

//       loyal_usersto["labels"] = loyal_labels_d
//       loyal_usersto["datasets"] = { label: "Users", data: loyal_datasets_d }


//       this.setState({
//         total_users_chart:total_usersto,
//         active_users_chart:active_usersto,
//         loyal_users_chart:loyal_usersto
//       })
//       // this.setState({active_users_chart:})
//     }).catch(err => {
//       console.log(err)
//     })
//   }
  
  
//   render() {
//     return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <FilterNavbarBasic />
//         <MDBox mt={4.5}>

//           {/* <Grid size={{ xs: 12, lg: 3 }}>
//             <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
//               <CustomizedTreeView />
//               <ChartUserByCountry />
//             </Stack>
//           </Grid> */}
            
//             <Grid item style={{"display":"flex", width:"100%"}}>
//                 <MDBox item xs={12} lg={6} style={{"display":"flex", width:"80%"}}>
//                   <ReportsLineChart
//                     color="info"
//                     title={`Общее число пользователей: ${this.state.chart.users_chart.total_users_count}`}
//                     description={
//                       <>
//                         {/* (<strong>+15%</strong>) increase in today sales. */}
//                       </>
//                     }
//                     date="just updated"
//                     chart={this.state.total_users_chart}
//                   />
//                 </MDBox>
//                 <MDBox item ml={1} xs={12} lg={6} style={{"display":"flex", width:"20%"}}>
//                   <CustomPieChart 
//                     chart={this.state.chart}
//                     />
//                 </MDBox>
//             </Grid>

//           {/* <Grid container spacing={3}> */}
//             <Grid item pt={3} xs={12} md={6} lg={4}>
//               <MDBox mt={3} mb={3}>
//                 <ReportsLineChart
//                   color="success"
//                   title="Активные игроки"
//                   description="Заходят в игру более 2х раз в сутки"
//                   date="just updated"
//                   chart={this.state.active_users_chart}
//                 />
//               </MDBox>
//             </Grid>
//           {/* </Grid> */}

//           {/* <MDBox mt={3}> */}
//             {/* <Grid container spacing={3}> */}
//               <Grid item pt={3} xs={12} md={6} lg={4}>
//                 <MDBox mb={3}>
//                   <ReportsLineChart
//                     color="warning"
//                     title="Лояльные игроки"
//                     description="Заходят в игру не более 1го раза в сутки"
//                     date="just updated"
//                     chart={this.state.loyal_users_chart}
//                   />
//                 </MDBox>
//               </Grid>
//             {/* </Grid> */}
//           {/* </MDBox> */}

//         </MDBox>

//       </DashboardLayout>
//     )
//   }}

export default Basic;