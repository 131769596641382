import Dashboard from "./layouts/dashboard";
import Activity from "./layouts/activity";
import Basic from "./layouts/basic";
import Geo from "./layouts/geo";
import RCBudget from "./layouts/rc_budget";
import RCMetrics from "./layouts/rc_metrics";
import Referral from "./layouts/referral";


import Tables from "./layouts/tables";
import Notifications from "./layouts/notifications";
import Profile from "./layouts/profile";
import SignIn from "./layouts/authentication/sign-in";
import SignUp from "./layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon className="material-icons" fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Basic",
    key: "basic",
    icon: <Icon className="material-icons" fontSize="small">trending_up</Icon>,
    route: "/basic",
    component: <Basic />,
  },
  {
    type: "collapse",
    name: "Activity",
    key: "activity",
    icon: <Icon className="material-icons" fontSize="small">sports_esports</Icon>,
    route: "/activity",
    component: <Activity />,
  },
  {
    type: "collapse",
    name: "Geo",
    key: "geo",
    icon: <Icon className="material-icons" fontSize="small">public</Icon>,
    route: "/geo",
    component: <Geo />,
  },
  {
    type: "collapse",
    name: "RC Budget",
    key: "rc_budget",
    icon: <Icon className="material-icons" fontSize="small">attach_money</Icon>,
    route: "/budget",
    component: <RCBudget />,
  },
  {
    type: "collapse",
    name: "RC Metrics",
    key: "rc_metrics",
    icon: <Icon className="material-icons" fontSize="small">ads_click</Icon>,
    route: "/metrics",
    component: <RCMetrics />,
  },
  {
    type: "collapse",
    name: "Referral",
    key: "referral",
    icon: <Icon className="material-icons" fontSize="small">group</Icon>,
    route: "/referral",
    component: <Referral />,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
