import * as React from 'react';
import PropTypes from 'prop-types';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';



var rounded = function(number){
  return +number.toFixed(2);
}

const StyledText = styled('text', {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme }) => ({
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fill: (theme.vars || theme).palette.text.secondary,
  variants: [
    {
      props: {
        variant: 'primary',
      },
      style: {
        fontSize: theme.typography.h5.fontSize,
      },
    },
    {
      props: ({ variant }) => variant !== 'primary',
      style: {
        fontSize: theme.typography.body2.fontSize,
      },
    },
    {
      props: {
        variant: 'primary',
      },
      style: {
        fontWeight: theme.typography.h5.fontWeight,
      },
    },
    {
      props: ({ variant }) => variant !== 'primary',
      style: {
        fontWeight: theme.typography.body2.fontWeight,
      },
    },
  ],
}));

function PieCenterLabel({ primaryText, secondaryText }) {
  const { width, height, left, top } = useDrawingArea();
  const primaryY = top + height / 2 - 10;
  const secondaryY = primaryY + 24;

  return (
    <React.Fragment>
      
      <StyledText variant="primary" x={left + width / 2} y={primaryY}>
        {String(primaryText)}
      </StyledText>
        
      
      <StyledText variant="secondary" x={left + width / 2} y={secondaryY}>
        {secondaryText}
      </StyledText>
    </React.Fragment>
  );
}

PieCenterLabel.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired,
};



export default function ChartUserByCountry(
  chart
  ) {
    
    const total_amount = Number(chart.chart.users_chart.total_users_count)

    const active_players_percent = rounded((chart.chart.active_users_chart.total_active_users / total_amount)*100, 4)
    const loyal_players_percent = rounded((chart.chart.active_users_chart.total_loyal_users / total_amount)*100, 2)
    const inactive_players_percent = rounded((chart.chart.users_chart.inactive_users_count / total_amount)*100, 2)

    const other_percent = rounded(Number(100 - (active_players_percent+loyal_players_percent+inactive_players_percent)), 2)
    const other_abs = total_amount -(chart.chart.active_users_chart.total_active_users+chart.chart.active_users_chart.total_loyal_users+chart.chart.users_chart.inactive_users_count)

    // const chart_data = {
    //   "active_players_percent":{
    //     name: `Активные (${chart.chart.active_users_chart.total_active_users})`,
    //     value: chart.chart.active_users_chart.total_active_users,
    //     percent: active_players_percent,
    //     flag: <></>,
    //     color: '#66BB6A'
    //   },
    //   "loyal_players_percent": {
    //     name: `Лояльные (${chart.chart.active_users_chart.total_loyal_users})`,
    //     value: chart.chart.active_users_chart.total_loyal_users,
    //     percent: loyal_players_percent,
    //     flag: <></>,
    //     color: '#FFA726'
    //   },
    //   "inactive_players_percent":{
    //     name: `Неактивные (${chart.chart.users_chart.inactive_users_count})`,
    //     value: chart.chart.users_chart.inactive_users_count,
    //     percent: inactive_players_percent,
    //     flag: <></>,
    //     color: '#EF5350'
    //   }
    // }
    // if (other_abs > 0) {
    //   chart_data["other"] = {
    //       name: `Other (${other_abs})`,
    //       value: other_abs,
    //       percent: other_percent,
    //       flag: <></>,
    //       color: 'hsl(220, 25%, 20%)'
    //     }
    //   }
    const chart_data = {
      "active_players_percent":{
        name: `Активные`,
        value: chart.chart.active_users_chart.total_active_users,
        percent: active_players_percent,
        flag: <></>,
        color: '#66BB6A'
      },
      "loyal_players_percent": {
        name: `Лояльные`,
        value: chart.chart.active_users_chart.total_loyal_users,
        percent: loyal_players_percent,
        flag: <></>,
        color: '#FFA726'
      },
      "inactive_players_percent":{
        name: `Неактивные`,
        value: chart.chart.users_chart.inactive_users_count,
        percent: inactive_players_percent,
        flag: <></>,
        color: '#EF5350'
      }
    }
   
    if (other_abs > 0) {
      chart_data["other"] = {
          name: `Other`,
          value: other_abs,
          percent: other_percent,
          flag: <></>,
          color: 'hsl(220, 25%, 20%)'
        }
      }
    // Create items array
    var items = Object.entries(chart_data).map(function([key, v]) {
      return [key, chart_data[key].value];
    });

    // Sort the array based on the second element
    items.sort(function(first, second) {
      return second[1] - first[1];
    });

    const colors = Object.keys(chart_data).map(function(key) {
      return chart_data[key].color
    });

    const data = Object.keys(chart_data).map(function(key) {
      return {label: chart_data[key].name, value: chart_data[key].value};
    });

  return (
    <Card
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1, height: "100%" }}
    >
      <CardContent sx={{ height: "100%" }}>
        {/* <Typography variant="h6">
          User activity
        </Typography> */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PieChart
            colors={colors}
            margin={{
              left: 80,
              right: 80,
              top: 80,
              bottom: 80,
            }}
            series={[
              {
                data,
                innerRadius: 75,
                outerRadius: 100,
                paddingAngle: 0,
                highlightScope: { faded: 'global', highlighted: 'item' },
              },
            ]}
            height={220}
            width={220}
            slotProps={{
              legend: { hidden: true },
            }}
          >
            <PieCenterLabel primaryText={String(total_amount)} secondaryText="Total" />
          </PieChart>
        </Box>
        <div>
        {Object.values(chart_data).map((item, index) => <>
          <Stack
            key={index}
            direction="row"
            sx={{ alignItems: 'center', gap: 2, pb: 2 }}
          >
            {item.flag}
            
            <Stack sx={{ gap: 1, flexGrow: 1 }}>
              <Stack
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.875rem'}}>
                  {item.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {item.percent}%
                </Typography>
              </Stack>
              <LinearProgress
                variant="determinate"
                aria-label="Number of users by activity"
                value={item.value}
                sx={{
                  [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: item.color,
                  },
                }}
              />
            </Stack>
          </Stack>
      </>
      )}
      </div>
      </CardContent>
    </Card>
  );
}