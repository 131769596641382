import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Typography from '@mui/material/Typography';

import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";

import Breadcrumbs from "../../Breadcrumbs";
import NotificationItem from "../../Items/NotificationItem";

// Custom styles for FilterBasic
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "./styles";

import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "../../../context";

function FilterBasic({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  
  useEffect(() => {
    // Setting the navbar type
    setNavbarType("static")
    setTransparentNavbar(dispatch, true)
    // A function that sets the transparent state of the navbar.
    // function handleTransparentNavbar() {
    //   setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    // }

    // window.addEventListener("scroll", handleTransparentNavbar);

    // // Call the handleTransparentNavbar function to set the state with the initial value.
    // handleTransparentNavbar();

    // Remove event listener on cleanup
    return;
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon className="material-icons">email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon className="material-icons">podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon className="material-icons">shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar style={{minHeight: "auto", width: "100%"}} sx={(theme) => navbarRow(theme, { isMini })}>
        {/* <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox> */}
        {isMini ? null : (
        <MDBox style={{width: "100%"}}>
          <MDBox style={{display: "flex", justifyContent: "space-between", width: "100%"}} sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className="material-icons" sx={iconsStyle} fontSize="medium">
                  menu
                </Icon>
              </IconButton>
              
              <IconButton
                  size="medium"
                  disableRipple
                  color="inherit"
                  >
                  <Icon className="material-icons" sx={iconsStyle} fontSize="medium">
                      filter_alt
                  </Icon>
              </IconButton>
              <MDBox style={{display: "flex", justifyContent: "left", width: "100%", gap:"0.5rem", alignItems:"center"}} color={light ? "white" : "inherit"}>
                
                <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.75rem'}}>
                  Всего
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.75rem'}}>
                  24 часа
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.75rem'}}>
                  Неделя
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '0.75rem'}}>
                  Месяц
                </Typography>
              
              {/* {renderMenu()} */}
            </MDBox>
            <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className="material-icons" sx={iconsStyle}>notifications</Icon>
              </IconButton>
          </MDBox>
        </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of FilterBasic
FilterBasic.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the FilterBasic
FilterBasic.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default FilterBasic;
